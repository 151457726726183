import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import main from '../assets/images/functions/api/main.png';
import main2x from '../assets/images/functions/api/main_2x.png';
import headLeft from '../assets/images/functions/api/head_left.png';
import headRight from '../assets/images/functions/api/head_right.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/api/1.png';
import first2x from '../assets/images/functions/api/1_2x.png';
import second from '../assets/images/functions/api/2.png';
import second2x from '../assets/images/functions/api/2_2x.png';
import third from '../assets/images/functions/api/3.png';
import third2x from '../assets/images/functions/api/3_2x.png';
import fourth from '../assets/images/functions/api/4.png';
import fourth2x from '../assets/images/functions/api/4_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';

const faqs = [
  {
    question: 'Where can I find the API token?',
    answer: 'You can find the API token in your Account Settings > Additional.',
  },
  {
    question: 'How can I change the API token?',
    answer:
      'You can generate a new API token by clicking on Change in the Additional tab in Account Settings.',
  },
  {
    question:
      'Where can I find documentation regarding API and relevant examples?',
    answer:
      'The most recent documentation is available on our website under Automatization and API.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'API automation',
    description:
      'The current API version allows creating, editing, deleting, and receiving profiles with the necessary fingerprint settings, proxies, cookies, and tags. Additionally, you can use it to start and stop the profiles.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Browser automation framework',
    description:
      'You can use any browser automation framework (Puppeteer, Selenium, CDP).',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Choose your programming language',
    description:
      'Use the programming language you like: Go, Ruby, Python, or PHP.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Hybrid work mode',
    description:
      'You can combine working with profiles through API automation with the manual mode depending on the tasks at hand.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
];

const ApiAutomationPage = (): JSX.Element => (
  <FunctionsPageTemplate faq={faqs}>
    <HeadSection
      description="Automate all interactions with your accounts and profiles using any browser automation framework."
      leftBgImage={headLeft}
      rightBgImage={headRight}
      title="API automation"
    />
    <MainSection
      description="Ditch manual tweaking and repetitive actions: optimize your account and profile tasks using API."
      image={main}
      image2x={main2x}
      title={
        <Trans i18nKey="Automate routine actions">
          <BlueSpan>Automate</BlueSpan> routine actions
        </Trans>
      }
    />
    <PossibilitiesSection
      cards={cardsInfo}
      title={
        <Trans i18nKey="API features">
          API <BlueSpan>features</BlueSpan>
        </Trans>
      }
    />
  </FunctionsPageTemplate>
);

export default ApiAutomationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["apiAutomationPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
